import * as React from 'react';
import * as styles from './Content.module.scss';

const Content = ({ children, isFullWidth }) => {
  return (
    <div className={
      [
        styles.container,
        isFullWidth ? styles.containerIsFullWidth : null
      ].join(' ')
    }>
      { children }
    </div>
  )
};

export default Content;
