import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/Layout';
import EnviromentPageSection from "../../components/EnviromentPageSection";

const EnvironmentAndSustainabilityPage = ({data}) => {
    const {contentfulBasicPageSection, lowerSection} = data;
    return (
        <>
            <Layout>
                <main>
                    <EnviromentPageSection
                        enviromentPage={contentfulBasicPageSection}
                        lowerSection={lowerSection}
                        isEnvironmentContentColumns
                    />
                </main>
            </Layout>
        </>
    )
}

export default EnvironmentAndSustainabilityPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection: contentfulBasicPageSection(
            contentful_id: {eq: "67D5RTX0CyY35WYi9KMNhV"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
        lowerSection: contentfulBasicPageSection(
            contentful_id: {eq: "2HHutZziLRosBsVsNxWoFw"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
    }
`;