import * as React from 'react';
import * as styles from './ContentColumns.module.scss';

const ContentColumns = ({ children, isFullWidth, isReverseColumnOnMobile, isEnvironmentContentColumns }) => {
  return (
    <div className={
      [
        styles.container,
        isFullWidth ? styles.containerIsFullWidth : null,
        isReverseColumnOnMobile ? styles.containerIsReverseColumnOnMobile : null,
        isEnvironmentContentColumns ? styles.isEnvironmentContentColumns : null
      ].join(' ')
    }>
      { children }
    </div>
  )
};

export default ContentColumns;