import * as React from 'react';
import * as styles from './Subtitle.module.scss';

const Subtitle = ({ children, isLargeSubtitle, isAboutUsPage, isPrintingPage, isBasicPageSection  }) => {
  return (
    <div className={[
        styles.container,
        isLargeSubtitle ? styles.isLargeSubtitle : null,
        isAboutUsPage ? styles.isAboutUsPage : null,
        isPrintingPage  ? styles.isPrintingPage : null,
        isBasicPageSection  ? styles.isBasicPageSection : null,
      ].join(' ') }>
      { children }
    </div>
  )
};

export default Subtitle;