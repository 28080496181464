import * as React from "react";
import * as styles from "./DoubleImage.module.scss";

const DoubleImage = ({ firstImageSrc, secondImageSrc, firstImageDescription, secondImageDescription }) => {
	return (
		<div className={styles.container}>
			<div className={styles.col1}>
				<figure>
					<div className={styles.imageContainer}>
						<img className={styles.image} src={firstImageSrc} alt={""} />
					</div>
					<div className={styles.textContainer}>
						<figcaption className={styles.textBox}>
							{firstImageDescription}
						</figcaption>
					</div>
				</figure>
			</div>
			<div className={styles.col2}>
				<figure>
					<div className={styles.imageContainer}>
						<img className={styles.image} src={secondImageSrc} alt={""} />
					</div>
					<div className={styles.textContainer}>
						<figcaption className={styles.textBox}>
							{secondImageDescription}
						</figcaption>
					</div>
				</figure>
			</div>
		</div>
	);
};

export default DoubleImage;
